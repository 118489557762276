const React = require('react');
const { bool, string } = require('prop-types');
const { loadable } = require('nordic/lazy');
const { RecommendationsCarouselFree } = require('@recommendations-fe/carousel-free');
const RecommendationsIconFactory = require('./icon-factory');
const ContextTemplate = require('./polycard-context');

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const RecommendationsCardVertical = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardVertical,
});

RecommendationsCardVertical.preload();

const measuringHeightLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringHeight,
});

const measuringHeight = measuringHeightLoader.preload();

const measuringFlagLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringFlag,
});

const measuringFlag = measuringFlagLoader.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const measuringFunctions = isPolycard ? { measuringHeight, measuringFlag } : {};

  const CardTemplate = cardProps =>
    isPolycard ? (
      <Polycard polycard={{ ...cardProps }} className="recos-polycard" />
    ) : (
      <RecommendationsCardVertical {...cardProps} />
    );

  return (
    <RecommendationsCarouselFree
      Card={CardTemplate}
      IconFactory={RecommendationsIconFactory}
      Context={ContextTemplate}
      contextProps={{
        type: 'grid-card',
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      paddingHorizontal={16}
      {...measuringFunctions}
      {...props}
    />
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

module.exports = Recommendations;
